import bigPackageIcon from './../../images/packages/net300.svg';
import chrome from './../../images/packages/icon-chrome.svg';
import film from './../../images/packages/icon-film.svg';
import microphone from './../../images/packages/icon-microphone.svg';
import midPackageIcon from './../../images/packages/net150.svg';
import music from './../../images/packages/icon-music.svg';
import net1000New from './../../images/packages/net-1000-new.svg';
import { packageType } from '../../constants/packages';
import twitch from './../../images/packages/icon-twitch.svg';
import xlPackageIcon from './../../images/packages/net500.svg';
import xxlPackageIcon from './../../images/packages/net1000.svg';

class InternetPackage {
	iconsList = [chrome, film, microphone, music, twitch];

	constructor(id, size, speed, price, features) {
		this.id = id;
		this.title = features.package;
		this.isOnlyInternet = true;
		this.internetSpeed = speed;
		this.internetSpeedNumberStrike = speed?.split('|')[0];
		this.internetSpeedNumber = speed?.split('|')[1];
		this.orderMobile = features.orderMobile;
		this.internalTitle = features.internalname || '';
		this.currentPrice = features.promotionalPrice === 'Не' ? null : parseFloat(price);
		this.originalPrice =
			features.promotionalPrice === 'Не'
				? parseFloat(price)
				: parseFloat(features.promotionalPrice);

		this.promotionalText =
			features.promotionalText === 'Не' ? null : features.promotionalText;

		this.packageExtras = [
			{
				text: `<b>Тип Ток <br/>${features.batteryTipTokPriceExtras}</b><br/>батерия за рутера, чрез която имаш нет дори, когато нямаш ток.`,
				active: true,
				renderHtml: true
			}
		];

		this.packageFeatures = this.initFeatures(features, size);
		this.duration = parseInt(features.duration);
		this.showInformationButton = !!features.informationButton;
		this.informationButtonText = features.informationButton;
		this.fetchedPackage = features;
		this.showPromoPackageBadge = features.textPromoBadge != 0;
		this.textPromoBadge = features.textPromoBadge;
		this.promoBadgeBgColor = features.promoBadgeBgColor || '#6C4EE2';
		this.promoBadgeTextColor = features.promoBadgeTextColor || '#FFFFFF';
		this.promoBadgeInfoTooltip = features.promoBadgeInfoTooltip;
		this.tvAppIncluded = features.auroraTvDesign === 'Да';

		this.staticIp = features.staticIp > 0;

		this.showMostOrderedBadge = !!features.textMostOrdered;
		this.textMostOrdered = features.textMostOrdered;
		this.mostOrderedBgColor = features.mostOrderedBgColor || '#5FFEB2';
		this.mostOrderedTextColor = features.mostOrderedTextColor || '#FFFFFF';

		this.showPromoBadge = !!features.textPromoOrdered;
		this.textPromoOrdered = features.textPromoOrdered;
		this.promoOrderedBgColor = features.promoOrderedBgColor || '#6C4EE2';
		this.promoOrderedTextColor = features.promoOrderedTextColor || '#FFFFFF';

		this.pauses = features.pauses;

		this.wiFiExtenderTenda = features.wiFiExtenderTendaPriceExtras;

		if (features.staticIpPrice) {
			this.packageExtras.push({
				text: `<b>Статично IP - ${features.staticIpPrice}</b><br/>`,
				active: true,
				renderHtml: true
			});
		}

		if (this.wiFiExtenderTenda) {
			this.packageExtras.push({
				text: `<b>Wi-Fi Екстендер</b><br/>Тенда<br/><b>${this.wiFiExtenderTenda}</b><br/>Wi-Fi удължител<br/>`,
				active: true,
				renderHtml: true
			});
		}

		if (features.hboCinemaxPackPrice) {
			this.packageExtras.push({
				text: `<b>HBO & Cinemax - ${features.hboCinemaxPackPrice}</b><br/>`,
				active: true,
				renderHtml: true
			});
		}

		if (features.diemaExtraPackPrice) {
			this.packageExtras.push({
				text: `<b>Diema Extra - ${features.diemaExtraPackPrice}</b><br/>`,
				active: true,
				renderHtml: true
			});
		}

		if (features.maxSportPackPrice) {
			this.packageExtras.push({
				text: `<b>MAX Sport - ${features.maxSportPackPrice}</b><br/>`,
				active: true,
				renderHtml: true
			});
		}

		if (features.sevenEightPackPrice) {
			this.packageExtras.push({
				text: `<b>7/8 TV - ${features.sevenEightPackPrice}</b><br/>`,
				active: true,
				renderHtml: true
			});
		}

		this.ontFetcher = features.ont;
		this.ontFetcherValue = features.ontcount;
		this.ontFetcherInfo = features.tooltipOnt;
		this.ontFetcherColor = features.ontColor;
		this.ontFetcherHeader = features.ontHeader;

		this.hdFetcherInfo = features.tooltipStbAurora;

		switch (this.internalTitle.toLowerCase()) {
			case 'net 85':
				this.icon = midPackageIcon;
				break;
			case 'net 150':
				this.icon = bigPackageIcon;
				break;
			case 'net 300':
				this.icon = xlPackageIcon;
				break;
			case 'net 500':
				this.icon = xxlPackageIcon;
				break;
			case 'net 1000':
				this.icon = net1000New;
				break;
			default:
				this.icon = bigPackageIcon;
				break;
		}
	}

	initFeatures = (features, size) => {
		let result = [];
		const { timeShift, archive, record } = features;
		result.push({
			text: `<b>${features.pauses} паузи по договор</b>`,
			active: features.pauses !== 0,
			renderHtml: true
		});

		if (timeShift || archive === 'Да' || record === 'Да') {
			const extras = [
				timeShift && `връщане назад (${features.timeShift} дни)`,
				archive === 'Да' && 'видео архив',
				record === 'Да' && 'търсачка и запис'
			];
			result.push({
				text: `<b>Екстри:</b> ${extras
					.filter((extra) => extra !== false)
					.join(', ')}`,
				active: true,
				renderHtml: true
			});
		}

		if (features.timeShift5 !== 'Не' && features.package !== packageType.internet) {
			this.packageExtras.push({
				text: `<b>Връщане 5 дни на тв предавания - ${features.timeShift5}</b>`,
				active:true,
				renderHtml: true
			});
		}
		if (features.timeShift7 !== 'Не' && features.package !== packageType.internet) {
			this.packageExtras.push({
				text: `<b>Връщане 7 дни на тв предавания - ${features.timeShift7}</b>`,
				active:true,
				renderHtml: true
			});
		}

		return result.sort(this.sortPackages);
	};

	// Sort packages to show the true values first
	sortPackages = (p1, p2) => {
		return p1.active === p2.active ? 0 : p1.active ? -1 : 1;
	};

	getPresentationPrice = () => String(this.originalPrice.toFixed(2)).split('.');

	getPriceMain = () => this.getPresentationPrice()[0];
	getPriceCoins = () => {
		return this.getPresentationPrice()[1].slice(0, 2);
		// return 99;
	};

	toString = () => {
		return (
			'\n<br>Пакет: ' +
			this.title +
			'\n<br>Интернет скорост: ' +
			this.duration +
			'\n<br>Базова цена: ' +
			String(this.originalPrice).split('.')[0] +
			'.' +
			String(this.originalPrice)
				.split('.')[1]
				.slice(0, 2) +
			'\n<br>Цена на конфигурацията: ' +
			this.getPriceMain() +
			'.' +
			this.getPriceCoins()
		);
	};
}

export default InternetPackage;

